import Vue                                  from "vue";
import {Loading, Notify, QSpinnerHourglass} from "quasar";
import axios                                from "./Services/Requests/AxiosUtils.js";
import moment                               from "moment";
import decimal                              from "decimal.js";
import accounting                           from "accounting";
import permisos                             from "./permisos";
import modal                                from "./components/modal";
import permisosError                        from "./components/permisosError";
import inputMoney                           from "./components/inputMoney";
import svgIcon                              from "./components/svgIcon";

Vue.config.productionTip = false;
Vue.config.devtools      = process.env.NODE_ENV !== "production";

/*
 |--------------------------------------------------------------------------
 | Registro de componentes globales para la aplicación
 |--------------------------------------------------------------------------|
 */
Vue.component("modal", modal);
Vue.component("permisos-error", permisosError);
Vue.component("q-input-money", inputMoney);
Vue.component("svg-icon", svgIcon);

Object.defineProperties(Vue.prototype, {
  permisos: {get: () => permisos},
  Decimal: {get: () => decimal},
  accounting: {get: () => accounting},
  moment: {get: () => moment},
  scrollStyle: { get: () => {
      return {
        right: '2px',
        borderRadius: '5px',
        backgroundColor: '#525961',
        width: '6px',
        opacity: 0.75
      }
    }
  },
  dateFormat: {
    get: () => (fecha, formato = 1, showHoras = true) => {
      let date        = moment(fecha),
          horas       = showHoras ? date.format("HH:mm:ss") : "",
          mesParceado = date.format("MMM").replace(".", ""),
          mes         = mesParceado.charAt(0).toUpperCase() + mesParceado.slice(1);
      switch (formato) {
        // yyyy-mm-dd
        case 1:
          return !fecha ? "--" : `${date.format("YYYY-MM-DD")} ${horas}`;
        // dd/mmm/yy
        case 2:
          return !fecha ? "--" : `${date.format("DD")}/${mes}/${date.format("YY")}  ${horas}`;
        // dd/mm/yy
        case 3:
          return !fecha ? "--" : `${date.format("DD/MM/YY")} ${horas}`;
        // dd mmm
        case 4:
          return !fecha ? "--" : `${date.format("DD")} ${mes} ${horas}`;
        // hoy || ayer || dd mmm
        case 5:
          if (!fecha)
            return "--";
          else if (date.isSame(moment(), "day"))
            return `Hoy ${horas}`;
          else if (date.isSame(moment().subtract(1, "days"), "day"))
            return `Ayer ${horas}`;
          else
            return `${date.format("DD")} ${mes} ${horas}`;
      }
    }
  },
  axiosCancelToken: {get: () => axios.getAxiosCancelToken()},
  axiosSourceToken: {set: (token) => axios.setAxiosSourceToken(token)},
  loader: {
    get: () => (show, mensaje = "", color = "white") => {
      if (show) {
        document.onkeydown = () => { return false; };
        Loading.show({
          spinner: QSpinnerHourglass,
          message: mensaje,
          messageColor: "white",
          spinnerSize: 60,
          spinnerColor: color
        });
      } else {
        Loading.hide();
        document.onkeydown = () => { return true; };
      }
    }
  },
  alertShow: {
    get: () => (mensaje, tipo = "", position = "top") => {
      let conf = {
        message: mensaje, position,
        html: true,
        classes: "my-notify",
        actions: [{icon: "close", color: "white"}]
      };

      switch (tipo) {
        case "exito":
          conf.color = "positive";
          conf.timeout =  3000;
          break;
        case "error":
          conf.color = "negative";
          conf.timeout =  6000;
          break;
        case "warning":
          conf.color = "warning";
          conf.timeout =  6000;
          break;
        default:
          conf.color = "secondary";
          conf.timeout =  10000;
      }

      Notify.create(conf);
    }
  }
});

Vue.prototype.colorStatusBadge = function (status, statusFinal = 200) {
  switch (Number(status)) {
    case 100:
    case 500:
      return 'secondary';
    case 101:
      return 'dark';
    case 200:
      return statusFinal === 200 ? 'positive' : 'primary';
    case 201:
      return 'purple-8';
    case 300:
      return 'negative';
    case 301:
      return 'orange-6';
    case 302:
      return 'negative';
    case 400:
      return statusFinal === 200 ? 'primary' : 'positive';
  }
};

/*
 |--------------------------------------------------------------------------
 | Registro de directivas
 |--------------------------------------------------------------------------
 */
Vue.directive("ingresar", {
  bind(el, binding) {
    el.addEventListener("keydown", e => {
      //Se omiten las teclas del, espacio, tab, esc y enter
      let special = [46, 8, 9, 27, 13];
      if (binding.modifiers["decimales"]) {
        // Si existe teclado numerico agregamos sus teclas
        special.push(110, 190);
      }
      // Aceptamos combinaciones de teclas basicas sobre inputs
      if (
        special.indexOf(e.keyCode) !== -1 ||
        // Ctrl+A
        (e.keyCode === 65 && e.ctrlKey === true) ||
        // Ctrl+C
        (e.keyCode === 67 && e.ctrlKey === true) ||
        // Ctrl+X
        (e.keyCode === 88 && e.ctrlKey === true) ||
        // home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)
      ) {
        return;
      }
      if (
        binding.modifiers["letras"] &&
        // Solo letras
        e.keyCode >= 65 &&
        e.keyCode <= 90
      ) {
        return;
      }
      if (
        binding.modifiers["numeros"] &&
        // Solo numeros enteros
        ((!e.shiftKey && e.keyCode >= 48 && e.keyCode <= 57) ||
          // Si existe teclado numerico agregamos sus teclas
          (e.keyCode >= 96 && e.keyCode <= 105))
      ) {
        return;
      }
      e.preventDefault();
    });
  }
});

export default Vue;