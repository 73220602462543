//
//
//
//
//

export default {
  created () {
    this.$q.iconMapFn = (iconName) => {
      if (iconName.startsWith('idt:') === true) {
        const name = iconName.substring(4);
        return {
          cls: name
        }
      }
    }
  }
}
