import axios         from "./Requests/AxiosConfig"
import axiosUtils    from "./Requests/AxiosUtils"
import VentaServices from "./VentaServices";
import RequestAxios from "../Repositories/RequestAxios";

const ventaServices = new VentaServices();

export default class LoginServices {
  async realizarLogin(data) {
    return this.iniciarSession(data);
  }

  async iniciarSession(data) {
    let timestamp = new Date().getTime(),
        metodo    = "usuarios/login",
        hash      = axiosUtils.obtenerHash(timestamp + metodo);

    let form = {
      ...data,
      sistema: "MOSTRADOR",
      timestamp,
      hash: hash.substr(0, 12),
      apikey: localStorage.getItem("envApikey")
    };
    let urlRequest = localStorage.getItem('apiUrl') + metodo;
    return new Promise((resolve, reject) => {
      axios.post(urlRequest, axiosUtils.getPostParams(form))
        .then(resp => {
          let data = resp.data;
          if (data.codigo !== 200)
            throw resp.data.mensaje;

          localStorage.setItem("usuarioId", data.data.usuarioId);
          localStorage.setItem("usuario", data.data.usuario);
          localStorage.setItem("pin", data.data.pinEmpresa);
          localStorage.setItem("pinUsuario", data.data.pinUsuario);
          localStorage.setItem("nombre", data.data.nombreCompleto);
          localStorage.setItem("perfilesAcceso", data.data.perfilAcceso);
          localStorage.setItem("empresaId", data.data.empresaId);
          localStorage.setItem("modulos", JSON.stringify(data.data.modulosContratados));
          localStorage.setItem("nombreSuscripcion", data.data.nombreSuscripcion);
          localStorage.setItem("tipoSuscripcion", data.data.tipoSuscripcion);
          localStorage.setItem("usuariosContratados", data.data.usuariosContratados);
          localStorage.setItem("tiendasContratadas", data.data.tiendasContratadas);
          localStorage.setItem("fechaSuscripcion", data.data.fechaSuscripcion);
          localStorage.setItem("proximaRenovacion", data.data.proximaRenovacion);
          localStorage.setItem("montoRenovacion", data.data.montoRenovacion);
          localStorage.setItem("fechaUltimoAcceso", data.data.fechaUltimoAcceso);
          localStorage.setItem("registroFecha", data.data.registroFecha);
          localStorage.setItem("listaPermisos", JSON.stringify(data.data.listaPermisos));
          localStorage.setItem("listaTiendas", JSON.stringify(data.data.listaTiendas));
          localStorage.setItem("imagen", data.data.imagen);
          localStorage.setItem("global", data.data.global);
          localStorage.setItem("apikey", data.data.apiKey);
          localStorage.setItem("hashkey", data.data.hashKey);
          localStorage.setItem("keyRedisSesion", data.data.keyRedisSesion);
          localStorage.setItem("permitirCancelacionCfdi", true);
          localStorage.setItem("menu", "1");

          resolve();
        }).catch(error => axiosUtils.axiosCatch(error, () => reject(error)));
    });
  }

  cargarDatosIniciales() {
    return ventaServices.cargaInicial();
  }

  async obtenerSesionActivaRedis(filtros) {
    let url       = "usuarios/obtenerSesionActivaRedis",
        timestamp = new Date().getTime(),
        hash      = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

    let data = {...filtros, timestamp, hash: hash.substr(0, 12)};
    return RequestAxios.requestGET(url, data);
  }
}