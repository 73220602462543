export default {
  getApi() {
    let isProducction = process.env.NODE_ENV === "production";
    let pathBase = isProducction ? process.env.VUE_APP_BASE_URL : '/';
    return new Promise(resolve => {
      if (isProducction) {
        fetch(pathBase + 'properties.json')
          .then(response => response.json())
          .then(data => {
            //localStorage.clear();
            localStorage.setItem("apiUrl", data.API_URL);
            localStorage.setItem("envApikey", data.APIKEY);
          }).catch(err => console.log(err));
      } else {
        localStorage.setItem("apiUrl", process.env.VUE_APP_BASE_API);
        localStorage.setItem("envApikey", process.env.VUE_APP_APIKEY);
      }
      resolve();
    });
  }
}
