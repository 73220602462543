import permisos              from "../permisos";
import ValidarPermisoService from "../Services/ValidarPermisoService";
//import ValidarSesionService  from "../Services/ValidacionSesionService";

export default [
  {path: "/login", component: () => import("../views/login"), name: "login"},
  {
    path: "/",
    component: () => import("../components/layouts/Template"),
    //beforeEnter: (_,__, next) => ValidarSesionService.obtenerSesionActivaRedis(next),
    children: [
      //Importante agregar la validacion en cada ruta que se agregue (aunque no requiera permiso ingrese null), ya que
      //contiene la validacion de otro usuario que haya iniciado sesion en otro lugar
      {path: "/home", component: () => import("../views/home"), name: "home"},
      {path: "ventaMostrador", component: () => import("../views/ventas/ventaMostrador"), name: "ventaMostrador",
        beforeEnter: (to, from, next) => ValidarPermisoService.validarAccesoRuta(permisos.PERMISO_VENTAS_MOSTRADOR, next, to, from)},
      {path: "cajaMostrador/:id?", component: () => import("../views/caja/cajaMostrador"), name: "cajaMostrador",
        beforeEnter: (to, from, next) => ValidarPermisoService.validarAccesoRuta(permisos.PERMISO_MODULO_CAJA, next, to, from)},
      {path: "corteCaja", component: () => import("../views/corte/corteCajaMostrador"), name: "corteCaja", 
        beforeEnter: (to, from, next) => ValidarPermisoService.validarAccesoRuta(null, next, to, from)},
      //inventario
      {path: "salidaLotesSeries/:id?", component: () => import("../views/inventario/salidaLotesSeries.vue"), name: "salidaLotesSeries",
        beforeEnter: (to, from, next) => ValidarPermisoService.validarAccesoRuta(permisos.PERMISO_VENTAS_MOSTRADOR, next, to, from)},
    ]
  }
];