import axios   from "axios";
import {merge} from "lodash";

const CancelToken = axios.CancelToken;
let source        = CancelToken.source();
export default {
  axiosCatch(error, callback) {
    if (!axios.isCancel(error) && typeof callback === "function") {
      callback(error)
    }
  },
  getAxiosCancelToken() { return CancelToken },
  setAxiosSourceToken(token) { source = token },
  getParams(datos = []) {
    let session = localStorage;
    return merge({
      apikey: session.getItem("apikey"),
      registroAutorId: session.getItem("usuarioId"),
      pin: session.getItem("pin"),
      tiendaId: !_.isEmpty(session.tienda) ? JSON.parse(session.getItem("tienda")).tiendaId : "",
    }, datos);
  },
  getPostParams(form, blockAutorId) {
    let session  = localStorage,
        formData = new FormData();
    formData.append("apikey", session.getItem("apikey"));
    if (_.isEmpty(form.pin)) formData.append("pin", session.getItem("pin"));
    if (!blockAutorId) formData.append("registroAutorId", session.getItem("usuarioId"));
    if (!_.isEmpty(session.tienda)) formData.append("tiendaId", JSON.parse(session.getItem("tienda")).tiendaId);

    for (let property in form) {
      if (Object.prototype.hasOwnProperty.call(form, "archivos"))
        formData.append("archivos", form[property]);
      else if (Object.prototype.hasOwnProperty.call(form, "files"))
        form[property].forEach(file => formData.append("files[]", file));
      else formData.append(property, form[property]);
    }
    return formData;
  },
  getJsonType() {return {headers: {'Content-Type': 'application/json'}};},
  obtenerHash(str) {
    let SHA512 = require("crypto-js/sha512");
    return SHA512(str).toString();
  }
}