export default {
  existePermiso: (permiso) => {
    let permisos = JSON.parse(localStorage.getItem("listaPermisos")) || [];
    return _.filter(permisos, p => p.codigo === permiso).length === 1;
  },

  PERMISO_VENTAS_MOSTRADOR: "evelyn.geco.venta.operacion.ventasMostradorAcceder",
  PERMISO_MODULO_CAJA: "evelyn.geco.venta.operacion.cajaMostradorAcceder",
  PERMISO_CONSULTAR_VENTAS: "evelyn.geco.venta.operacion.ventasConsultar",
  PERMISO_COTIZACION_PRECIO_MANUAL_APLICAR: "evelyn.geco.venta.operacion.cotizacionPrecioManualCambiar",
  PERMISO_VENTAS_PRECIO_MANUAL_APLICAR: "evelyn.geco.venta.operacion.ventaPrecioManualCambiar",
  PERMISO_COTIZACION_PRECIO_LISTA_APLICAR: "evelyn.geco.venta.operacion.cotizacionPrecioListaCambiar",
  PERMISO_VENTAS_PRECIO_LISTA_APLICAR: "evelyn.geco.venta.operacion.ventaPrecioListaCambiar",
  PERMISO_COTIZACION_DESCUENTO_PRODUCTO_APLICAR: "evelyn.geco.venta.operacion.cotizacionDescuentoPorProductoAplicar",
  PERMISO_COTIZACION_DESCUENTO_APLICAR: "evelyn.geco.venta.operacion.cotizacionDescuentoGlobalAplicar",
  PERMISO_VENTAS_DESCUENTO_PRODUCTO_APLICAR: "evelyn.geco.venta.operacion.ventaDescuentoPorProductoAplicar",
  PERMISO_VENTAS_DESCUENTO_APLICAR: "evelyn.geco.venta.operacion.ventaDescuentoGlobalAplicar",
  PERMISO_VENTAS_AGREGAR_PAGO: "evelyn.geco.venta.operacion.ventaPagoAgregar",
  PERMISO_VENTAS_CLIENTE_DATO_FISCAL_AGREGAR: "evelyn.geco.venta.cliente.datoFiscalAgregar",
  PERMISO_VENTAS_CLIENTE_DATO_FISCAL_EDITAR: "evelyn.geco.venta.cliente.datoFiscalEditar",
  PERMISO_VENTAS_FACTURAR: "evelyn.geco.venta.facturacion.facturaTimbrar",
  PERMISO_CORTE_CONSULTAR_OPERACION: "evelyn.geco.venta.operacion.cajaOperacionResumenConsultar",
  PERMISO_CORTE_INCREMENTO_FONDO: "evelyn.geco.venta.operacion.cajaOperacionIncrementoFondo",
  PERMISO_CORTE_RETIRO_PARCIAL: "evelyn.geco.venta.operacion.cajaOperacionRetiroParcial",
  PERMISO_CORTE_CIERRE_CAJA: "evelyn.geco.venta.operacion.cajaOperacionCerrar",
}