import Vue    from "./bootstrap"
import App    from "./App.vue"
import router from "./router"
import config from "./Services/Global/getConfig"
import "./quasar"
import "moment/locale/es"

config.getApi().then(() => {
  new Vue({
    router,
    render: h => h(App)
  }).$mount("#app");
});
