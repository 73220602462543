import axios      from "../Services/Requests/AxiosConfig"
import axiosUtils from "../Services/Requests/AxiosUtils";

export default {
  requestGET(url, data) {
    return new Promise((resolve, reject) => {
      let params = {params: axiosUtils.getParams(data)};
      axios.get(url, params).then(resp => {
        let data = resp.data;
        if (data.codigo !== 200)
          throw data.mensaje;
        resolve(data.data);
      }).catch(err => axiosUtils.axiosCatch(err, error => reject(error)));
    });
  },
  requestPOST(url, data, blockAutorId = false) {
    return new Promise((resolve, reject) => {
      let params = axiosUtils.getPostParams(data, blockAutorId);
      axios.post(url, params).then(resp => {
        let data = resp.data;
        if (data.codigo !== 200)
          throw data.mensaje;
        resolve(data);
      }).catch(err => axiosUtils.axiosCatch(err, error => reject(error)));
    });
  }
}