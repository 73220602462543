//
//
//
//
//
//
//

export default {
  name: "svgIcon",
  props: [
    "name",
    "color",
    "size",
    "classes"
  ]
}
