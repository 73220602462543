import RequestAxios         from "../Repositories/RequestAxios";
import axiosUtils           from "./Requests/AxiosUtils";
import CargaInicialServices from "./CargaInicialServices";

export default class VentaServices {
  async obtenerPublicoGeneral() {
    let url       = "ventaMostrador/obtenerPublicoGeneral",
        timestamp = new Date().getTime(),
        hash      = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

    let data = {timestamp, hash: hash.substr(0, 12)};
    return RequestAxios.requestGET(url, data);
  }

  async cargaInicial(filtros) {
    let url       = "ventaMostrador/obtenerCargaInicial",
        timestamp = new Date().getTime(),
        hash      = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

    let data = {
      ...filtros,
      timestamp,
      hash: hash.substr(0, 12),
      status: 200,
      orden: 5,
      configuracion: 1,
      cajaId: localStorage.getItem("cajaId") || "",
    };

    return new Promise((resolve, reject) => {
      RequestAxios.requestGET(url, data).then(async resultado => {
        await CargaInicialServices.guardarCargaInicial(resultado);
        resolve();
      }).catch(error => reject(error));
    });
  }

  async obtenerCategorias() {
    let url       = "ventaMostrador/listarCategoriasPadre",
        timestamp = new Date().getTime(),
        hash      = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

    let data = {timestamp, hash: hash.substr(0, 12)};
    return RequestAxios.requestGET(url, data);
  }

  async obtenerSubcategorias(filtros) {
    let url       = "ventaMostrador/listarSubcategoriasPorCategoria",
        timestamp = new Date().getTime(),
        hash      = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

    let data = {...filtros, timestamp, hash: hash.substr(0, 12)};
    return RequestAxios.requestGET(url, data);
  }

  async buscarClientes(filtros) {
    let url       = "ventaMostrador/buscarClientes",
        timestamp = new Date().getTime(),
        hash      = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

    let data = {...filtros, timestamp, hash: hash.substr(0, 12)};
    return RequestAxios.requestGET(url, data);
  }

  async agregarCliente(dataAdd) {
    let url       = "ventaMostrador/agregarCliente",
        timestamp = new Date().getTime(),
        hash      = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

    let data = {...dataAdd, timestamp, hash: hash.substr(0, 12)};
    return RequestAxios.requestPOST(url, data);
  }

  async listarMarcas() {
    let url       = "ventaMostrador/listarMarcas",
        timestamp = new Date().getTime(),
        hash      = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

    let data = {timestamp, hash: hash.substr(0, 12)};
    return RequestAxios.requestGET(url, data);
  }

  async obtenerCatSubcategoria(filtros) {
    let url       = "ventaMostrador/obtenerCategoriaSubcategoria",
        timestamp = new Date().getTime(),
        hash      = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

    let data = {...filtros, timestamp, hash: hash.substr(0, 12)};
    return RequestAxios.requestGET(url, data);
  }

  async listarProductosVenta(filtros) {
    let url       = "ventaMostrador/listarProductosVenta",
        timestamp = new Date().getTime(),
        hash      = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

    let data = {...filtros, timestamp, hash: hash.substr(0, 12)};
    return RequestAxios.requestGET(url, data);
  }

  async validarPermisoCredenciales(filtros) {
    let url       = "ventas2/validarPermisoCredenciales",
        timestamp = new Date().getTime(),
        hash      = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

    let data = {...filtros, timestamp, hash: hash.substr(0, 12)};
    return RequestAxios.requestPOST(url, data);
  }

  async registrarVenta(params, blockAutorId) {
    let url       = "ventaMostrador/agregarVenta",
        timestamp = new Date().getTime(),
        hash      = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

    let data = {...params, timestamp, hash: hash.substr(0, 12)};
    return RequestAxios.requestPOST(url, data, blockAutorId);
  }

  async obtenerPdf(filtros) {
    let url       = "ventaMostrador/obtenerPdf",
        timestamp = new Date().getTime(),
        hash      = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

    let data = {...filtros, timestamp, hash: hash.substr(0, 12)};
    return RequestAxios.requestGET(url, data);
  }

  async listarVentas(filtros) {
    let url       = "ventas2/listar",
        timestamp = new Date().getTime(),
        hash      = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

    let data = {...filtros, timestamp, hash: hash.substr(0, 12)};
    return RequestAxios.requestGET(url, data);
  }
  async obtenerDetalleVenta(filtros) {
    let url       = "ventas2/obtener",
        timestamp = new Date().getTime(),
        hash      = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

    let data = {...filtros, timestamp, hash: hash.substr(0, 12)};
    return RequestAxios.requestGET(url, data);
  }

  async obtenerImpuestos(filtros) {
    let url       = "impuestos/obtenerImpuestosProducto",
        timestamp = new Date().getTime(),
        hash      = axiosUtils.obtenerHash(timestamp + url + localStorage.getItem("hashkey"));

    let data = {...filtros, timestamp, hash: hash.substr(0, 12)};
    return RequestAxios.requestGET(url, data);
    }
}