export default {
  guardarCargaInicial(data) {
    return new Promise(resolve => {
      let clon             = _.clone(data.publicoGeneral);
      clon.nombreComercial = data.publicoGeneral.nombreComercial || data.publicoGeneral.nombre_comercial;
      let caja             = data.caja;

      localStorage.setItem("publicoGeneral", JSON.stringify(clon));
      localStorage.setItem("almacenes", JSON.stringify(data.almacenes));
      localStorage.setItem("configVentas", data.configuracionVentas[0].valor);
      localStorage.setItem("configImpuestos", JSON.stringify(data.configuracionImpuestos));
      localStorage.setItem("almacenSeleccionado", JSON.stringify(_.filter(data.almacenes, al => al.esDefault)[0]));
      localStorage.setItem("formasPago", JSON.stringify(data.formasPago));
      localStorage.setItem("usosCfdi", JSON.stringify(data.usosCfdi));
      localStorage.setItem("regimenFiscales", JSON.stringify(data.regimenFiscales));
      localStorage.setItem("metodosPago", JSON.stringify(data.metodosPago));
      localStorage.setItem("tipoRelacion", JSON.stringify(data.tipoRelacion));
      localStorage.setItem("tiendaDetalle", JSON.stringify(data.tiendaDetalle));
      localStorage.setItem("tienda", JSON.stringify(_.find(JSON.parse(localStorage.getItem("listaTiendas")), {tiendaId: caja.tienda_id})));
      localStorage.setItem("cajaObj", JSON.stringify(caja));
      localStorage.setItem("operacionCajaId", caja.operacionCajaId.toString());
      localStorage.setItem("operacionCaja", JSON.stringify(caja.operacionCaja));
      localStorage.setItem("datoFiscalPublicoGeneral", JSON.stringify(data.datoFiscalPublicoGeneral));
      resolve();
    });

  },
}