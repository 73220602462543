//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "modal",
  props: {
    model: Boolean,
    globalClases: String,
    titulo: String,
    mensajeBanner: String,
    bodyClass: String,
    footerClass: String,
    footerHidden: {type: Boolean, default: false},
    closeHidden: {type: Boolean, default: false},
    alertWarning: {type: Boolean, default: false},
    alertSuccess: {type: Boolean, default: false},
    alertInformation: {type: Boolean, default: false},
  },
  methods: {
    onClose() {
      this.$emit("on-close");
    },
    onShow() {
      this.$emit("on-show");
    }
  }
}
