import permisos from '../permisos';
import {Notify} from "quasar";
import LoginServices from "./LoginServices";

const loginServices = new LoginServices();

export default {
  mostrarAlert (mensaje, tipo = "", position = "top"){
    let conf = {
      message: mensaje, position,
      timeout: 2500 * 2,
      html: true,
      classes: "my-notify",
      actions: [{icon: "close", color: "white"}]
    };

    switch (tipo) {
      case "exito":
        conf.color = "green-8";
        break;
      case "error":
        conf.color = "red-8";
        break;
      case "warning":
        conf.color = "amber-8";
        break;
      default:
        conf.color = "grey-2";
    }

    Notify.create(conf);
  },
  validarAccesoRuta(permiso, next, to, from) {
      let usuarioId = localStorage.getItem("usuarioId");
      let pin       = localStorage.getItem("pin");
      let key       = localStorage.getItem("keyRedisSesion");
      let token     = localStorage.getItem("tokenLogin");
      let filtros   = { key: pin + "-MOSTRADOR-" + usuarioId };

      loginServices.obtenerSesionActivaRedis(filtros)
      .then((res) => {
        console.log(res);
        if (key == res) {
          next();
        }else{
          this.mostrarAlert("Alguien más ha iniciado sesión con este usuario y ha finalizado su sesión actual, \nya que no es posible mantener más de una sesión activa.", "warning");
          localStorage.clear();
          next({name: "login", query: {token}});
        }
        
      })
      .catch(error => this.alertShow(error, "error"));

      if (permiso) {
          if (!permisos.existePermiso(permiso)) {
            let mensaje = "No cuenta con los permisos necesarios para realizar esta acción(" + permiso + ")";
            this.mostrarAlert(mensaje,"warning");
            next({ name: from.name });
          } else if (to.name === "ventaMostrador" && localStorage.getItem("bloqueoVentas") === "true") {
            this.mostrarAlert("Verifique que la caja cuenta con la opción de venta y se encuentre una opreración de caja activa.","warning");
            next({name: "home"});
          } else if (to.name === "cajaMostrador" && localStorage.getItem("bloqueoCaja") === "true") {
            this.mostrarAlert("La caja no tiene la configuración para aceptar pagos ó se requiere operacion de caja activa.","warning");
            next({name: "home"});
          } else if (to.name === "corteCaja" && localStorage.getItem("bloqueoCorte") === "true") {
            this.mostrarAlert("La configuración de caja no permite el ingreso a la sección de corte de caja.","warning");
            next({name: "home"});
          } else {
            next();
          }
      }
  }
}