//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "permisosError",
  props: {
    permiso: {type: String, default: ""},
  }
}
