import Vue from "vue"
import 'quasar/dist/quasar.css'
import lang from 'quasar/lang/es.js'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import '@quasar/extras/material-icons-round/material-icons-round.css'
import '@quasar/extras/material-icons-sharp/material-icons-sharp.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import '@quasar/extras/ionicons-v4/ionicons-v4.css'
import '@quasar/extras/mdi-v4/mdi-v4.css'
import '@quasar/extras/eva-icons/eva-icons.css'
import '@quasar/extras/material-icons/material-icons.css'
import "@quasar/extras/line-awesome/line-awesome.css"
import "./assets/css/estilos.css"
import "./assets/icons/idt-icon/idt-icon.css"
import {Quasar, Notify} from "quasar"

Vue.use(Quasar, {
  config: {
    brand: {
      primary: "#006EA8",
      secondary: "#616972",
      positive: "#1A9228",
      negative: "#DB2031",
      info: "#1250D7",
      warning: "#DA6B11"
    }
  },
  components: {
    /* not needed if importStrategy is not 'manual' */
  },
  directives: {
    /* not needed if importStrategy is not 'manual' */
  },
  plugins: {Notify},
  lang: lang
});