import Vue from "vue";
import VueRouter from "vue-router";
import rutas from "./rutas";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    base: process.env.VUE_APP_BASE_URL,
    routes: rutas
});

router.beforeEach((to, from, next) => {
    const urlsPublicas = ["login"];
    const autenticacionRequerida = !urlsPublicas.includes(to.name);
    const logueado = localStorage.getItem("hashkey");
    const validTokenLogin = (localStorage.getItem("tokenLogin") || "") === (_.isEmpty(to.query) ? "" : to.query.token);

    if (!validTokenLogin && to.name === "login" && localStorage.getItem("tokenLogin") !== null) {
        let token = to.query.token;
        localStorage.clear();
        next({name: "login", query: {token}});
    } else if (autenticacionRequerida && logueado === null) {
        let token = JSON.stringify({pin:localStorage.pin,cajaId:localStorage.cajaId,tituloLogin:localStorage.tituloLogin});
        next({name: "login", query: {token: btoa(token)}});
    } else if (to.name === "login" && logueado !== null) {
        next("/ventaMostrador");
    } else if (logueado !== null && to.fullPath === "/") {
        next("/ventaMostrador");
    } else next();
});

export default router;
