import axios from "axios";
import router from "../../router";


const CancelToken = axios.CancelToken;
let source = CancelToken.source();
const http = axios.create({
  baseURL: localStorage.apiUrl,
  headers: {
    'Content-Type': 'multipart/form-data'
  },
  responseType: 'json',
});

http.interceptors.request.use(
  config => {
    let apiURL = localStorage.getItem("apiUrl");
    if (apiURL) {
      http.defaults.baseURL = localStorage.getItem("apiUrl");
      config.baseURL = localStorage.getItem("apiUrl");
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(response => {
  if (response.data.codigo === 405) {
    source.cancel();
    let token = JSON.stringify({pin:localStorage.pin,cajaId:localStorage.cajaId,tituloLogin:localStorage.tituloLogin});
    localStorage.clear();
    router.push({name: "login", query: {token: btoa(token)}});
    return Promise.reject(new axios.Cancel('.'));
  }
  return response;
}, (error) => {
  if (!error.response) {
    return Promise.reject("Error: Falla en la petición al servidor. Verifique su conexión a internet");
  }
  if (!error.response.data) {
    return Promise.reject("Los parametros enviados no son validos o estan incompletos.");
  }
  return Promise.reject(error.response);
});

export default http;
